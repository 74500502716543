// colors

$themeprimary: #2874f0;
$themeSecondary: #af291f;

// button animation

// mufeeda

.productVariant {
  .swiper-wrapper {
    display: flex !important;
  }
}

.null-margin-calculate {
  margin: unset !important;
}

.custom-padding-brand {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.sub-Brand {
  .sub-brand-slider-container .swiper-wrapper {
    justify-content: flex-start !important;
  }

  .sub-brand-slider-container .swiper-wrapper.align-center {
    justify-content: end !important;
  }
}

.custom-swiper {
  justify-content: flex-start !important;
}

.custom-swiper.align-center .swiper-wrapper {
  justify-content: center !important;
}

.custom-swiper.align-right .swiper-wrapper {
  justify-content: flex-end;
}

.logo-sub-banner-white-box {
  background-color: white;
  left: 20px;
}
.custom-list {
  &::-webkit-scrollbar {
    width: 2px !important;
    background-color: #f2f2f2 !important;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #232b35 !important;
  }
}

@media screen and (max-width: 768px) {
  .sub-brand-slider-container .swiper-wrapper {
    justify-content: flex-start !important;
  }
}

.custom-text-brand-div {
  background-image: url('/assets/images/brand/background.webp');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  .custom-brand-text {
    // font-size: 50px;
    font-weight: 600;
  }

  .text-brand-div {
    background: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(10px) !important;
  }
}

// ameen start //
.check-box-style {
  span {
    color: #4168d8 !important;
  }
}
.MuiFormLabel-asterisk {
  color: red !important;
}

.location-input {
  .MuiInputBase-root {
    padding-right: 30px !important;
  }

  .css-tdqp9c-MuiInputAdornment-root {
    margin-right: 2px !important;
  }

  input::placeholder {
    font-size: 12px !important;
  }

  input {
    font-size: 14px;
    min-width: 80px !important;
    font-weight: 600;
  }

  div {
    button:nth-child(1) {
      display: none;
    }
  }
}

.checkout-mobile-button {
  position: fixed !important;
  bottom: 74px;
  left: 0;
  border-radius: 0 !important;
  height: 60px;
  z-index: 9;
  width: 100%;
}

.mobile-checkout-accordion {
  box-shadow: none !important;
}

.full-width-tabs-custom-color {
  width: 100%;

  .MuiTabs-flexContainer {
    justify-content: space-between;

    button {
      flex: 1;
    }
  }

  .css-1obiyde-MuiTabs-indicator {
    background-color: $themeSecondary !important;
  }
}

.content-disabled {
  span,
  p,
  h6,
  .Mui-checked {
    color: #919eab !important;
    cursor: not-allowed;
  }

  div {
    cursor: not-allowed;
  }
}

.product-specification-table .MuiTableCell-root {
  height: 45px;
}

.banner-container {
  img {
    object-fit: cover;
  }
}

.custom-product-thumb-right-box {
  .slick-list {
    .slick-track {
      transform: none !important;
    }
  }
}

.width-fit-content {
  width: fit-content !important;
}

.product-cart-wrap {
  position: relative;
  .hover-image {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    visibility: hidden;
    transition: all ease-in-out 0.3s;
  }

  .hover-image-available {
    opacity: 1;
    visibility: visible;
    transition: all ease-in-out 0.3s;
  }

  @media screen and (min-width: 768px) {
    &:hover .hover-image {
      opacity: 1;
      visibility: visible;
    }

    &:hover .hover-image-available {
      opacity: 0;
      visibility: hidden;
    }
  }
  .out-of-stock-container {
    position: absolute;
    top: 50%;
    background-color: #3f3f3f85;
    width: 100%;
    text-align: center;
    transform: translateY(-50%);
  }
}

.bg-cart-icon {
  background-color: #f3f3fc;
  position: absolute;
  right: 8px;
  top: 8px;
  border-radius: 5px;
  z-index: 1;
  min-height: 30px;
  min-width: 30px;
  padding: 0 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all ease-in-out 0.2s;
  overflow: hidden;
}

.bg-cart-icon.ar-bg-cart-icon {
  right: unset !important;
  left: 8px !important;
}

.bg-cart-icon .cart-added {
  margin-right: -78px;
  opacity: 0;
  transition:
    margin-right 0.5s ease-in-out,
    opacity 0.5s ease-in-out;
}

.bg-cart-icon.added {
  /* min-height: 25px; */
  min-height: 30px;
  border-radius: 3px;
}

.bg-cart-icon.added .cart-icon-svg {
  opacity: 0;
  visibility: hidden;
  margin-right: -17px;
  transition:
    margin-right 0.8s ease-in-out,
    opacity 0.8s ease-in-out;
}

.bg-cart-icon.added .cart-added {
  margin-right: 0px;
  opacity: 1;
}

.bg-cart-icon .cart-added p {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.bg-cart-icon.added .cart-added p {
  opacity: 1;
}

.bg-cart-icon.added {
  background-color: $themeprimary;
  color: #fff;
  padding: 0 10px;
}

@media screen and (min-width: 768px) {
  .bg-cart-icon:hover {
    background-color: $themeprimary;
  }
  .bg-cart-icon:hover .cart-icon-svg path {
    fill: #fff;
  }
  .bg-wish-list {
    &:hover {
      background-color: #f3f3fc;

      // svg {
      //   // transform: scale(1.01);
      // }
    }
  }
}

// .product-cart-wrap:hover .cart-icon-svg path {
//   // transform: scale(1.05);
// }

.bg-cart-icon .cart-icon-svg path {
  transition: all ease-in-out 0.2s;
  fill: #000;
}

.slider-container {
  .slick-track {
    width: 100% !important;
  }
}

.slider-gap-10 {
  .slick-slide {
    margin-left: 10px;
  }
}

.sub-banner-slider {
  .slick-list {
    height: 100%;
  }

  .slick-slide:nth-child(1) {
    padding: 0px 20px 0px 0px !important;
  }

  .slick-slide {
    padding: 0px 10px 0px 10px !important;
  }

  img {
    max-height: 260px;
    object-fit: cover;
    border-radius: 16px;
    width: 100%;
  }
}

.padding-zero {
  padding: 0 !important;
}

.sub-brand-slider-container {
  .swiper-wrapper {
    justify-content: end;
  }
}

.cart-summery-box {
  box-shadow: none !important;
  margin: 0 10px !important;

  &::before {
    display: none !important;
  }
}

@media screen and (max-width: 600px) {
  .product-specification-box {
    &::before {
      display: none !important;
    }

    margin: 0 !important;
    padding: 10px 0px;
    border-radius: 0 !important;
    box-shadow: none !important;
    border: none !important;

    .MuiAccordionSummary-root {
      min-height: 25px !important;
    }

    .MuiAccordionSummary-content {
      margin: 0 !important;
    }

    table {
      th {
        padding: 0 !important;
        height: 35px;
      }
    }
  }

  .product-detail-action-button-group {
    position: fixed;
    bottom: 0px;
    padding: 0;
    gap: 0 !important;
    left: 0;
    z-index: 99;
    background-color: #fff;

    button {
      border-radius: 0 !important;
      height: 74px;
    }
  }
}

// ameen end //

// shibili //

.top-slider-tab {
  overflow-x: scroll;
}

.top-slider-tab::-webkit-scrollbar {
  display: none;
}

.pop-up-banner-button-container {
  position: absolute;
  bottom: -8px;
  right: -10px;
  z-index: 1;
  padding: 5px 10px 15px 5px;
  border-radius: 10px;
  min-width: 35%;
}

.pop-up-banner-button-container .popup-banner-button {
  padding: 20px 20px;
  border-radius: 8px;
  color: #fff;
  // display: flex;
  // justify-content: space-between;
  // align-items: center;
}

.pop-up-banner-button-container .circle-white-1 {
  background-color: #fff;
  height: 30px;
  width: 30px;
  position: absolute;
  bottom: 7px;
  left: -29px;
}

.pop-up-banner-button-container .white-curve-layer-one {
  width: 10px;
  height: 10px;
  position: absolute;
  bottom: 8px;
  left: -10px;
}

.pop-up-banner-button-container .white-box-el {
  width: 10px;
  height: 10px;
  position: absolute;
  bottom: 2px;
  left: -4px;
  background-color: white;
  transform: rotate(45deg);
}

.pop-up-banner-button-container .white-curve-layer-two {
  width: 10px;
  height: 10px;
  position: absolute;
  top: -10px;
  right: 10px;
}

.pop-up-banner-button-container .white-box-el-two {
  width: 10px;
  height: 12px;
  position: absolute;
  top: -5px;
  right: 5px;
  background-color: white;
  transform: rotate(45deg);
}

.pop-up-banner-button-container .circle-white-1 {
  background-color: #fff;
  height: 30px;
  width: 30px;
  position: absolute;
  bottom: 7px;
  left: -29px;
}

.pop-up-banner-button-container .white-curve-layer-one {
  width: 10px;
  height: 10px;
  position: absolute;
  bottom: 8px;
  left: -10px;
}

.pop-up-banner-button-container .white-box-el {
  width: 10px;
  height: 10px;
  position: absolute;
  bottom: 2px;
  left: -4px;
  background-color: white;
  transform: rotate(45deg);
}

.pop-up-banner-button-container .white-curve-layer-two {
  width: 10px;
  height: 10px;
  position: absolute;
  top: -10px;
  right: 10px;
}

.pop-up-banner-button-container .white-box-el-two {
  width: 10px;
  height: 12px;
  position: absolute;
  top: -5px;
  right: 5px;
  background-color: white;
  transform: rotate(45deg);
}

.home-slide-cover {
  position: relative;
}

.home-slide-cover .pop-up-banner-button-container {
  position: absolute;
  bottom: 8px;
  right: -10px;
  z-index: 1;
  padding: 5px 10px 8px 5px;
  border-radius: 10px;
  min-width: 35%;
}

.header-scroll.css-dbhgad-MuiToolbar-root {
  background-color: #232b35 !important;
}

.footer-icon-color-svg {
  fill: #7e7e7e !important;
}

.footer-icon-color-svg path {
  fill: #7e7e7e;
}

.mobile-nav {
  .css-168wit2-MuiPaper-root-MuiDrawer-paper {
    background-color: white !important;
  }
}

.auto-tag-input-2 {
  .css-gcgov3-MuiOutlinedInput-notchedOutline {
    // border-color: red;
    border-right: none;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .css-1r49bmg-MuiFormLabel-root-MuiInputLabel-root {
    display: none;
  }
}

.number-field {
  .css-gcgov3-MuiOutlinedInput-notchedOutline {
    // border-color: red;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
}

footer div div {
  background-color: #242b35 !important;
  border-radius: 0 !important;
  box-shadow: none !important;
}

.footer-accordian {
  &::before {
    display: none !important;
  }
}

.custom-avatar {
  .css-1pqm26d-MuiAvatar-img {
    object-fit: contain !important;
  }
}

.custom-phone-input {
  .css-gcgov3-MuiOutlinedInput-notchedOutline {
    border: none;
    //  border-left: 1px solid #efefef;
    //  border-radius: 0;
  }
  .css-v49clp {
    border: none;
  }
}

.ql-align-center {
  text-align: center !important;
  overflow-wrap: break-word;
}

.ql-align-right {
  text-align: right !important;
  overflow-wrap: break-word;
}

.ql-align-justify {
  text-align: justify !important;
  overflow-wrap: break-word;
}

.video-section {
  iframe {
    border-radius: 20px;
  }
}

.customerReview {
  .swiper-wrapper {
    display: flex !important;
  }
}

.customerReview {
  .swiper-slide-active {
    .review-slide {
      // max-height: 340px;
      background: rgba(123, 0, 99, 0.06);
      border-radius: 73px;
      transform: scale(1);
      @media screen and (max-width: 768px) {
        border-radius: 40px;
      }
    }
  }
  // margin-top: 5rem;
  // margin-bottom: 5rem;
  h4 {
    font-family: 'Ding';
    font-weight: 400;
    font-size: 38.5939px;
    line-height: 116.5%;
    display: flex;
    align-items: center;
    letter-spacing: -0.01em;
    color: #7b0063;
    margin: 0 8rem;
    margin-bottom: 5rem;
    @media screen and (max-width: 768px) {
      font-size: 38.5939px;
      text-align: center;
      width: 90%;
      margin: auto;
      margin-bottom: 30px;
    }
  }
  .review-slide {
    background: rgba(123, 0, 99, 0.06);
    border-radius: 5%;
    transform: scale(1);
    @media screen and (max-width: 768px) {
      border-radius: 40px;
    }
  }
  .review-slide {
    background: rgba(244, 244, 244, 0.65);
    border-radius: 35.0962px;
    padding: 50px;
    text-align: center;
    transform: scale(0.788);
    transition: all ease-in-out 0.3s;
    Avathar {
      width: 70px;
      height: 70px;
    }

    @media screen and (max-width: 768px) {
      h4 {
        font-size: 13.5px;
      }
      p {
        font-size: 13px;
      }
      .review-slide {
        padding: 20px !important;
        img {
          width: 30px;
          height: 30px;
        }
      }
    }
  }
}

// shibili end //
